import {
    ChangeDetectorRef,
    Directive,
    OnInit,
    TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IfShowPricingSupportService } from './if-show-pricing-support.service';

/**
 * Allows to easily show or hide pricing-related template-parts based on whether
 * pricing should be shown or not.
 */
@UntilDestroy()
@Directive({
    selector: '[mhpIfShowPricing]'
})
export class IfShowPricingDirective implements OnInit {
    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly ifShowPricingSupportService: IfShowPricingSupportService,
        private readonly changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.initViewToggleLogic();
    }

    private initViewToggleLogic() {
        this.ifShowPricingSupportService
            .isPricingVisible$()
            .pipe(untilDestroyed(this))
            .subscribe((pricingVisible) => {
                if (pricingVisible) {
                    this.viewContainer.createEmbeddedView(this.templateRef);
                    this.changeDetectorRef.markForCheck();
                } else {
                    this.viewContainer.clear();
                }
            });
    }
}
